

  

    .project_details_container{
      width: 100%;
      padding-top: 12vw;
    }
    .project_details_title_box_container{
      display: flex;
      flex-direction: column;
      padding: 6vw;
    }
  
    .project_details_title {
      padding: 0;
      margin: 0;
      font-size: 6.5vw;
      padding-left: 3rem;
      padding-right: 3rem;
      font-weight: 300;
      line-height: normal;
    }
  
    .project_details_options_container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 45px;
      margin-bottom: 124px;
      padding-left: 3rem;
      padding-right: 3rem;
    }
    .project_details_description{
      border-top: 2px solid rgb(224, 224, 224);
      margin-top: 1rem;
      padding-top: 1rem;

    }
  
  
    .project_details_boxes {
      width: 30%;
      padding-top: 2rem;
    }
    .project_details_boxes h6{
      font-size: 10px;
      color: rgb(172, 172, 172);
     }

     .project_details_coverImage{
      width: 100%;
      height: auto;
      padding: 4vw 9.5vw 4vw 9.5vw;
     }
     .project_details_laptopframe_video{

      width: 100vw;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 124px;
      margin-bottom: 124px;
     
     }

     .project_details_laptopframe_video_inner{
      height:34.4vw;
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      position: relative;
 
     }
     .project_details_laptopImage{
        position: absolute;
        top: 0px;
        width: 74.6vw;
        height: auto;
   
      }
      .project_details_video_one{
        height:auto;
        width: 55.5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
        padding-right: 3px;

      }

  /* Mobile */
  
  @media (max-width: 600px) {
    .project_details_container  {
      width: 100%;
      padding-top: 14vw;
    }
    .project_details_title {
      padding: 0;
      margin: 0;
      font-size: 11vw;
      margin-top: 2rem;
    }
    .project_details_options_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 3vw;
      margin-bottom: 45px;
      padding-left: 0rem;
      padding-right: 0rem;
    }
    .project_details_description{
      margin-top: 1rem;
      padding-top: 1rem;
    }
    .project_details_boxes {
    padding-top: 2rem;
    width: 100%;
    }
    .project_details_boxes h6{
     font-size: 2.8vw;
     color: rgb(172, 172, 172);
    }
    .project_details_coverImage {
      width: 100vw; 
      aspect-ratio: 1 / 1; 
      padding: 0;
      object-fit: cover; 
    }
    .project_details_laptopframe_video{
      margin-top: 45px;
      margin-bottom: 45px;
     
     }
  }
  